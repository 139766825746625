.signin{
    padding-left: 10rem;
    padding-right: 10rem;
    margin-bottom: 5rem; 
}

.header{
    font-size: 40px;
    padding: 1.5rem;
    font-weight: 300;
}
.mail-box {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
}

.input-box{
    border: 1.5px gray solid;
    padding: 1.2rem ;
}

.signin-button {
    border: 1px gray solid;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 0.5rem; 
    padding-bottom: 0.5rem;
    background-color: purple;
    max-width: 5000px;
    text-align: center;
}

.button-signin {
    font-size: 25px;
    font-weight: 300;
    cursor: pointer;
    color: #fff;
}

.signin-text{
    font-size: 25px;
    font-weight: 300;
}

@media screen and (max-width: 820px) {
    .signin{
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 5rem; 
         margin-top: 2rem;
    }

    .mail-box {
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
    }

    .signin-button {
        border: 1px gray solid;
        background-color: purple;
        max-width: 5000px;
        text-align: center;
    }

    .input-box{
        border: 1.5px gray solid;
        padding: 1rem ;
    }

    .header{
        font-size: 25px;
        padding: 1rem;
        font-weight: 400;
    }

    .signin-text{
        font-size: 25px;
        font-weight: 300;
    }

    .button-signin {
        font-size: 30px;
        font-weight: 400;
        cursor: pointer;
        color: #fff;    
    } 
}