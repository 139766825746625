@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

.content-table-display {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1.2rem;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: -5px 5px 20px rgba(0,0,0,0.5);
    margin-left: auto;
    margin-right: auto;
    text-align: left;  
    max-width: 1500px;
    width: 70%;
}

.content-table-display thead tr th {
    color: #f3f3f3;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}


.content-table-display thead tr, button  {
    background-color: purple;
    text-align: left;
    font-weight: bold;
}

button {
    font-size: 0.5rem;
}

.content-table-display th,
.content-table-display td {
    padding: 12px 15px;
}

.content-table-display tbody tr {
    border-bottom: 1px solid #dddddd;
}

.content-table-display tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;

}

.content-table-display tbody tr:last-of-type {
    border-bottom: 2px solid rgb(55, 0, 60);
}


@media only screen and (max-width: 319px) {
.content-table{
    margin-left: 10px;
}
}

/* @media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) { */

    .content-table {
    /* min-width: 650px; */
    /* width: 98vh; */
    /* margin-left: 32px; */
    /* margin-left: 15px;
    margin-right: 5px; */
    margin-top: 10rem;
    margin-bottom: 10rem;
    margin-right: 1px;
    }
/* } */



@media screen and (width: 280px), 
screen and (height: 653px) {

    .content-table {
        margin-left: 44px;
        margin-top: 17rem;
        margin-bottom: 17rem;
        margin-right: 1px;

    }
}



    @media screen and (width: 768px), 
    screen and (height: 1024px) {
    
        .content-table-display {
            width: 90%;
           
        } 
    }





@media only screen 
and (min-device-width : 350px) 
and (max-device-width : 420px) {

    .content-table-dsiplay th,
    .content-table-display td {
        padding: 12px 5px;
    }
    
    .content-table-display {
        font-size: 0.9rem;
       
    }
}


