.hero-container {
    background: url(../images/footbal-team-wallpaper.JPG) center center/cover no-repeat;
    height: 66vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    max-width: 5000px;
}

