@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

a {
    text-decoration: none;
  }

li {
 list-style-type: none;
}

.navbar{
    height: 13vh;
    line-height: 10vh;
    box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
    background-color: rgb(55, 0, 60);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    max-width: 5000px;
    width: 100%;
}

.logo {
    font-size: 2rem;
    color: #fff;
    margin: 0.5rem;
    margin-left: 2rem;
    cursor: pointer;
    font-weight: 400;
}

.nav-links{
    display: flex;
    justify-content: flex-end;
    width: 75%;
}

.nav-links li {
    color: #fff;
    font-size: 1.3rem;
    padding: 0 10px;
    margin: 0 10px;
    cursor: pointer;
    text-transform: capitalize;
}

.nav-links li:hover{
    color: purple;
}

.hero{
    text-align: center;
    margin-top: 15%;
}

.hero h1{
    text-align: center;
    font-size: 60px;
}

button{
    border: none;
    background: none;
    font-size: 17px;
}

.mobile-menu-icon{
    display: none;
}

@media (max-width: 600px) {
    .nav-links{
        display: none;
    }

    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style: none;
        box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
        left: 0;
        top: 100%;
        transition: all 0.5 ease-in-out;
        width: 100%;
        background-color: purple;
        z-index: 1;
        height: 200vh;
        
    }


    ul li{
        padding: 15px;
        width: 100%;
        transition: all 0.5 ease-in-out;
        text-align: center;
        color: #fff;
        font-size: 20px;
    }

    .mobile-menu-icon{
        position: absolute;
        display: block;
        right: 35px;
        font-size: 25px;
        color: white;
        background: transparent;
        
    }

    .hero h1{
        font-size: 50px;
    }

    .logo {
        font-size: 25px;
        margin: 35px;
    }   
}



@media screen and (width: 667px), 
screen and (height: 375px) {

.nav-links li {
  
    padding: 0 8px;
    margin: 0 8px;
    font-size: 0.9rem;
    
}

}