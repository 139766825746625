@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.fixtures-table{
    display: flex;
    justify-content:space-between;
    padding-bottom: 1rem;



}

.fixtures-table > p {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;

}

.fixture-card > div{
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1.2rem;
    border-radius: 5px;
    overflow: hidden;
    /* box-shadow: -5px 5px 20px rgba(0,0,0,0.5); */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 5000px;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.matchDate{
    font-size: 16px;
    padding-top: 0.8rem;
   
}

.matchTime {
    font-size: 14px;
    padding-top: 3rem;
}

.matchSerialNo{
    font-size: 8px;
    display: none;
}

.fixture:not(:last-child){
    border-bottom: 1px solid #e2e2e2;
}

.fixture-card > h1{
    padding-top: 1rem;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    max-width: 5000px;
    width: 100%;
}

a {
    color: black;
}


@media only screen 
and (max-device-width : 400px) {

    .fixture-card > div{
        justify-content: center;
        box-shadow: none;
      
    }
    .fixture-card > h1{
        justify-content: center;
        margin-top: 5rem;
        text-align: center;
    }
    
   

      .fixture:first-child{
       margin-top: 2rem;
    } 

      .fixture:last-child{
       margin-bottom: 10rem;
    } 
   

}



@media only screen 
and (min-device-width : 390px) and (max-device-width : 780px) {

    .fixture-card > div{
        justify-content: center;
        box-shadow: none;
      
    }
    .fixture-card > h1{
        justify-content: center;
        margin-top: 5rem;
        text-align: center;
    }
    
   

      .fixture:first-child{
       margin-top: 2rem;
    } 

      .fixture:last-child{
       margin-bottom: 10rem;
    } 
   

}

@media only screen 
and (min-device-width : 781px) and (max-device-width : 1500px) {

    .fixture-card > div{
        justify-content: center;
        box-shadow: none;
      
    }
    .fixture-card > h1{
        justify-content: center;
        margin-top: 10rem;
        text-align: center;
    }
    
   
     

   
    .fixture:last-child{
        margin-bottom: 20rem;
     }  

}

@media screen and (width: 412px), 
screen and (height: 915px){
    .fixture-card > h1{
        justify-content: center;
        margin-top: 20rem;
        text-align: center;
    }



}


@media screen and (width: 768px), 
screen and (height: 1024px) {

    .fixture-card > div{
        margin-bottom: 10rem;
    }

    .fixture-card > h1{
        margin-top: 30rem;
       
    }

}