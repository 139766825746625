  .is-centered .club-images {
    width: 150px;
    height: 150px;
    margin-top: 10px;
    padding-top: 10px;
    
  }
  
  .sided{
    display: flex;
    justify-content:space-between;
    text-align: center;
    
   
  }

  .section-club {
    max-width: 5000px;
    width: 100%;
  }

.club-card > div{
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
    padding: 1rem;
    margin-bottom: 3rem;
    margin-top: 10px;
    text-align: center;
}

.club-card h1{
    font-size: 25px;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 400;
}


.is-centered{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen 
and (max-device-width : 400px) {

.is-centered .club-images {
  width: 120px;
  height: 120px;
  margin-top: 3rem;
  padding-top: 10px;
}



.club-card > div{
  justify-content: center;
  box-shadow: none;
}



.club-card > div:first-child{
 margin-top: 4rem;
}
}


@media screen and (width: 375px), 
screen and (height: 812px){
 

  .club-card > div{
    justify-content: center;
    box-shadow: none;
  }

  .is-centered{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
  }

 
}


@media only screen 
and (min-device-width : 390px) and (max-device-width : 780px) {

.is-centered .club-images {
  width: 120px;
  height: 120px;
  margin-top: 4rem;
  padding-top: 10px;
}

.club-card > div{
  justify-content: center;
  box-shadow: none;
}




.club-card > div:first-child{
  margin-top: 12rem;
 }

 .club-card > div:last-child{
  margin-bottom: 13rem;
 }

 

}

@media screen and (width: 540px), 
screen and (height: 720px){

.is-centered .club-images {
  width: 120px;
  height: 120px;
  margin-top: 4rem;
  padding-top: 10px;
}


.club-card > div:first-child{
  margin-top: 12rem;
 }

 .club-card > div:last-child{
  margin-bottom: 13rem;
 }


}



@media only screen 
and (max-device-width : 300px) {
  .club-card > div{
    justify-content: center;
    box-shadow: none;
  }
}


@media screen and (width: 653px), 
screen and (height: 280px) {
.club-card h1{
  font-size: 25px;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 500;
}

}





@media screen and (width: 768px), 
screen and (height: 1024px) {

  .is-centered{
    margin-top: 7rem;
}

.club-card > div{
  margin-bottom: 10rem;


}

.club-images{
  margin-right: 5rem;
}

}