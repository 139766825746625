* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.match-title{
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 0.7rem;
    font-size: 18px;
    margin-top: 3rem;
    font-weight: 200;
}

.match-body{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 200;
    margin-bottom: 2rem;  
}

.serialNum-result{
    font-size: 8px;
    display: none;
}

.result {
    padding-left: 2rem;
    padding-right: 2rem;
    
}

.result-card > div{
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1.2rem;
    border-radius: 5px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 5000px;
    width: 100%;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
}

.result:not(:last-child){
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 0.7rem;
}

.match-scores {
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    font-size: 25px;
    font-weight: 300; 
}

.result-header{
    padding-top: 1rem;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    max-width: 5000px;
    width: 100%;
    margin-top: 5rem;
}
