* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.news-heading{
    text-align: center;
    font-size: 40px;
    font-weight: 300;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 0.5rem;
}

.post-title{
    font-weight: 400;
    font-size: 25px;
    padding-top: 0.5rem;
}

.news-date {
    font-size: 16px;
    text-align: left;
    padding-bottom: 1rem;
}

.news-serialNum{
    font-size: 8px;
    display: none;

}

.news-body{
    font-size: 18px;
    padding-bottom: 4rem;
}

.news{
padding-left: 5rem;
padding-right: 5rem;
}

.news:last-child {
    padding-bottom: 1.5rem;
}

.news:first-child {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
}

.news-section-wrapper{
    max-width: 5000px;
    width: 100%;
}


@media only screen 
and (max-device-width : 400px) {

    .news{
        padding-left: 5rem;
        padding-right: 5rem;
        margin-bottom: 9rem;
        }

        .news:first-child {
            padding-top: 8rem;
            padding-bottom: 1rem;
        }

        .news-heading{
            text-align: left;
            font-size: 40px;
            font-weight: 300;
            padding-left: 5rem;
            padding-right: 5rem;
            padding-top: 5rem;
        }

}

@media only screen 
and (min-device-width : 390px) and (max-device-width : 780px) {

    .news{
        padding-left: 5rem;
        padding-right: 5rem;
        margin-bottom: 11rem;
        }

        .news:first-child {
            padding-top: 8rem;
            padding-bottom: 1rem;
        }

        .news-heading{
            text-align: left;
            font-size: 40px;
            font-weight: 300;
            padding-left: 5rem;
            padding-right: 5rem;
            padding-top: 5rem;
        }

        .news-body{
            font-size: 18px;
            padding-bottom: 3.5rem;
        }
        
}

@media only screen 
and (min-device-width : 781px) and (max-device-width : 1500px) {

    .news{
        
        padding-left: 5rem;
        padding-right: 5rem;
        margin-bottom: 8rem;
        }

        .news:first-child {
            padding-top: 5rem;
            padding-bottom: 1rem;
        }

        .news-heading{
            font-size: 40px;
            padding-left: 5rem;
            padding-right: 5rem;
            padding-top: 5rem;
        }

        .news-body{
            font-size: 18px;
            padding-bottom: 1rem;
        }

       

}

@media screen and (width: 412px), 
screen and (height: 915px){


    .news{
        padding-left: 5rem;
        padding-right: 5rem;
        margin-bottom: 13rem;
        }

}



@media only screen 
and (min-device-width : 736px) 
and (max-device-width : 740px) {


    .news{
        padding-left: 5rem;
        padding-right: 5rem;
        margin-bottom: 13rem;
        }

}


@media only screen 
and (min-device-width : 667px) 
and (max-device-width : 670px) {

    .news{
        padding-left: 5rem;
        padding-right: 5rem;
        margin-bottom: 2rem;
        }

}

@media only screen 
and (min-device-width : 650px) 
and (max-device-width : 655px) {

    .news{
        padding-left: 5rem;
        padding-right: 5rem;
        margin-bottom: 2rem;
        }

}


@media screen and (width: 768px), 
screen and (height: 1024px) {

    .news{
        margin-bottom: 16rem;
        }

        .news-heading{
            text-align: left;
            font-size: 40px;
            font-weight: 300;
            padding-left: 5rem;
            padding-right: 5rem;
            padding-top: 5rem;
            margin-top: 10rem;
        }
}
