.footer-container{
    background-color: rgb(55, 0, 60);
    padding: 2rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 5000px;
    width: 100%;  
}

.footer-container-hamburger{
background-color: rgb(55, 0, 60);
padding: 2rem 0 1rem 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
opacity: 0.9;
display: none;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

.footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-input:hover {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid rgb(239, 255, 11);
    background-color: rgb(239, 255, 11);
}

.footer-links {
    width: 100%;
    max-width: 5000px;
    display: flex;
    justify-content: space-evenly;
    padding-left: 2rem;

}

.footer-link-wrapper{
    display: flex;
}

.footer-btn {
    color: white;
    width: 100px;
    height: 40px;
    background-color: var(--primary);
    background-color: transparent;
    border: 1px solid white;
    font-size: 20px;
    text-decoration: none;
}

.footer-btn:hover{
    color: rgb(241, 245, 22);
    width: 100px;
    height: 40px;
    background-color: var(--primary);
    background-color: transparent;
    border: 1px solid rgb(201, 241, 22);
    transition: all 0.3s ease-out;
    font-size: 20px;
    text-decoration: none;

}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    max-width: 160px;
    width: 100%;
    box-sizing: border-box;
    
}

.footer-link-items h2 {
    margin-bottom: 16px;
    color: #fff;
    font-weight: 400;
}


.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
    color: purple;
    transition: 0.3s ease-out;
}

.footer-email-form h2{
    margin-bottom: 2rem;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
    color: #fff;
    font-size: 24px;
}

.social-icon-link:hover{
    color: purple;
    font-size: 24px;
}

.social-media {
    max-width: 5000px;
    width: 100%;
}

.social-media-wrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 40px auto 0 auto;
}

.social-icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 240px;
    margin-right: 15px;
    margin-bottom: 15px;
}



.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 15px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-weight: 400;
}

.website-rights {
    color: #fff;
    margin-left: 5px;
    margin-bottom: 14px;
}

.website-rights:hover {
    color: rgb(255, 231, 17);
    margin-bottom: 16px;
}

.footer-logo {
    margin-right: 2rem;
}




